const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();



//Returns true for valid phone number or false for invalid phone number
export const isValidPhoneNumber = (numb) => {
    try {
        const number = phoneUtil.parseAndKeepRawInput(numb, 'US');
        var valid = phoneUtil.isValidNumber(number)
        return valid
    } catch (e) {
        console.log(e)
        return false
    }

}
//Formats valid numbers to E.164
export const formatNumber = (numb) => {
    const number = phoneUtil.parseAndKeepRawInput(numb, 'US');
    return phoneUtil.format(number, PNF.E164)
}
