import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import moment from "moment";
import { Slider } from "@mui/material";
import { TimelinePin } from "./timeline_pin";
import { Pin } from "./pin";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getTimeline } from "../../../managers/apimanager";
import CircularProgress from "@mui/material/CircularProgress";
const TimelineFeature = (props) => {
  const [timelineMode, setTimelineMode] = useState(false);
  const [timelineMinimized, setTimelineMinimized] = useState(true);
  const [sliderOptions, setSliderOptions] = useState({});
  const [currentSliderTime, setSliderTime] = useState(null);
  const [mapPoly, setMapPoly] = useState(null);
  const [timelineCoords, setTimelineCoords] = useState([]);
  const [timelineLoader, setTimelineLoader] = useState(false);
  async function setTimeLinePins(timelineCoords, timeRange) {
    //Sort by time ascending, oldest to newest
    timelineCoords.sort((a, b) => {
      return a.time - b.time;
    });
    console.log(`Number of coords before filter`, timelineCoords.length);

    //Get rid of coords that were saved over an hour ago
    timelineCoords = timelineCoords.filter((element) => {
      return element.time >= timeRange.min && element.time <= timeRange.max;
    });
    console.log(`Number of coords after filter`, timelineCoords.length);

    console.log(`Cleaning coords to be separated by 1 minute intervals before`);
    var cleaned = [];
    var current = null;
    var incrementMax = 1 * 60 * 1000;
    for (var coords of timelineCoords) {
      if (current) {
        if (coords.time - current.time >= incrementMax) {
          cleaned.push(coords);
          current = coords;
        }
      } else {
        current = coords;
        cleaned.push(coords);
      }
    }
    timelineCoords = cleaned;
    console.log(`Cleaned coords`, timelineCoords.length);
    setTimelineCoords(timelineCoords);
    var arr = [];

    var polyCoords = [];
    for (var timelineObject of timelineCoords) {
      arr.push(
        <TimelinePin
          tokenId={props.tokenId}
          // accountOwner={props.accountOwner}
          contact={props.accountOwner}
          time={timelineObject.time}
          lat={timelineObject.coords[0]}
          lng={timelineObject.coords[1]}
        />
      );
      polyCoords.push({
        lat: timelineObject.coords[0],
        lng: timelineObject.coords[1],
      });
    }
    if (mapPoly) {
      mapPoly.setPath(polyCoords);
    } else {
      var mapLine = new props.maps.Polyline({
        path: polyCoords,
        strokeColor: "#1982FC",
        strokeOpacity: 0.2,
        strokeWeight: 3,
        icons: [
          {
            icon: { path: props.maps.SymbolPath.FORWARD_CLOSED_ARROW },
            repeat: "100px",
          },
        ],
        geodesic: true,
      });
      mapLine.setMap(props.map);
      setMapPoly(mapLine);
    }

    props.setTimeLine({
      active: true,
      pins: arr,
    });
  }
  async function filterTimelinePins(sliderTime) {
    console.log(`Current Coords: `, timelineCoords.length);
    var arr = [];

    var polyCoords = [];
    var highlightedIndex = false;
    for (var i = 0; i < timelineCoords.length; i++) {
      var timelineObject = timelineCoords[i];
      if (timelineObject.time > currentSliderTime) continue;

      if (highlightedIndex) {
        var timeAwayFromSliderTime = sliderTime - timelineObject.time;
        timeAwayFromSliderTime =
          timeAwayFromSliderTime * Math.sign(timeAwayFromSliderTime);
        if (timeAwayFromSliderTime < timelineCoords[highlightedIndex].time) {
          timelineCoords[highlightedIndex].highlighted = false;
          highlightedIndex = i;
        }
      } else {
        highlightedIndex = i;
      }
    }
    if (highlightedIndex) timelineCoords[highlightedIndex].highlighted = true;
    for (var timelineObject of timelineCoords) {
      if (timelineObject.time > currentSliderTime) continue;
      arr.push(
        <TimelinePin
          tokenId={props.tokenId}
          // accountOwner={props.accountOwner}
          contact={props.accountOwner}
          time={timelineObject.time}
          lat={timelineObject.coords[0]}
          lng={timelineObject.coords[1]}
          highlighted={timelineObject.highlighted}
        />
      );
      polyCoords.push({
        lat: timelineObject.coords[0],
        lng: timelineObject.coords[1],
      });
    }
    console.log(
      `After filtering for coords past the slider time: `,
      arr.length
    );

    if (arr.length > 10) {
      arr = arr.slice(arr.length - 10);
      polyCoords = polyCoords.slice(polyCoords.length - 10);
    }

    if (mapPoly) {
      mapPoly.setPath(polyCoords);
    } else {
      var mapLine = new props.maps.Polyline({
        path: polyCoords,
        strokeColor: "#1982FC",
        strokeOpacity: 0.2,
        strokeWeight: 3,
        icons: [
          {
            icon: { path: props.maps.SymbolPath.FORWARD_CLOSED_ARROW },
            repeat: "100px",
          },
        ],
        geodesic: true,
      });
      mapLine.setMap(props.map);
      setMapPoly(mapLine);
    }
    props.setTimeLine({
      active: true,
      pins: arr,
    });
  }
  async function clickedTimeLineMode() {
    setTimelineLoader(true);
    if (!timelineMode) {
      console.log(`Entering Time Line Mode`);
      // var timeNow = new Date().getTime();
      var time = props.accountOwner.recentLocationTime;
      var hour = 1 * 60 * 60 * 1000;
      setSliderOptions({
        min: time - hour,
        max: time,
        default: time,
      });
      setSliderTime(time);
      console.log(sliderOptions);
      // var coords = require("./timelinedata.json");
      var timelineAPICall = await getTimeline(
        props.tokenId,
        props.contactId,
        props.linkId
      );
      var coords = timelineAPICall.data.locationHistory;
      //console.log(`Coords`, coords);
      setTimeLinePins(coords, { min: time - hour, max: time });
    }

    setTimelineMode(!timelineMode);
    setTimelineLoader(false);

    props.setMapOptions({
      center: {
        lat: props.accountOwner.recentLocationCoords[0] * 0.99998,
        lng: props.accountOwner.recentLocationCoords[1],
      },
      zoom: 16,
    });
  }
  async function changedTimeLineSlider(event) {
    // console.log(event.target.value);
    setSliderTime(event.target.value);
    filterTimelinePins(event.target.value);
    // var coords = require("./timelinedata.json");
    // var timelineAPICall = await getTimeline(
    //   props.tokenId,
    //   props.contactId,
    //   props.linkId
    // );
    // var coords = timelineAPICall.data.locationHistory;
    // console.log(`Coords`, coords);
    // setTimeLinePins(coords, sliderOptions);
  }
  return !timelineMode ? (
    <div style={styles.main_container_timeline_off}>
      <div
        style={styles.timeline_on_button}
        onClick={() => {
          if (!timelineLoader) clickedTimeLineMode();
        }}
      >
        {!timelineLoader && (
          <span>
            {props.tokenId
              ? "Timeline Feature"
              : `${props.accountOwner.name}'s Previous Locations`}
          </span>
        )}
        {timelineLoader && (
          <CircularProgress
            size={16}
            sx={{
              color: "white",
            }}
          />
        )}
      </div>
      {!props.tokenId && (
        <a
          href={`tel:${props.accountOwner.phone}`}
          style={styles.call_emergency_person_button}
        >
          <span>Call {props.accountOwner.name}</span>
        </a>
      )}

      <a href={`tel:911`} style={styles.call_911_button_timeline_off}>
        {!props.tokenId && <span>Call 911 for {props.accountOwner.name}</span>}
        {props.tokenId && <span>Call 911</span>}
      </a>
    </div>
  ) : (
    <div style={styles.main_container_timeline_on}>
      <div style={styles.timeline_on_buttons_container}>
        <a href={`tel:911`} style={styles.call_911_button_timeline_on}>
          <span>
            {props.tokenId
              ? "Call 911"
              : `Call 911 for ${props.accountOwner.name}`}
          </span>
        </a>
        <div style={styles.timeline_controls_container}>
          <span
            style={styles.exit_timeline_button}
            onClick={() => {
              setTimelineMode(!timelineMode);
              props.setTimeLine({ active: !timelineMode });
              if (mapPoly) {
                mapPoly.setMap(null);
                setMapPoly(null);
              }
              setSliderOptions({});
              setSliderTime(null);
              setTimelineMinimized(true);
            }}
          >
            Exit Timeline Mode
          </span>

          <span
            style={styles.timeline_info_button}
            onClick={() => {
              setTimelineMinimized(!timelineMinimized);
            }}
          >
            {timelineMinimized ? (
              <InfoIcon fontSize="medium" />
            ) : (
              <KeyboardArrowDownIcon fontSize="medium" />
            )}
          </span>
        </div>
      </div>

      <div style={styles.timeline_content_container}>
        {!timelineMinimized && (
          <span style={styles.timeline_title_tag}>
            An Hour Before{" "}
            {props.tokenId ? "Your" : `${props.accountOwner.name}'s`} Recent
            Location
          </span>
        )}
        {!timelineMinimized && (
          <span style={styles.timeline_time_title}>Time</span>
        )}
        <span style={styles.timeline_time_text}>
          {moment(currentSliderTime).format("ddd, MMM Do YYYY hh:mm:ss A")}
        </span>

        <Slider
          aria-label="Temperature"
          sx={styles.timeline_slider}
          min={sliderOptions.min}
          max={sliderOptions.max}
          step={60000}
          // marks={sliderOptions.marks}
          defaultValue={sliderOptions.default}
          onChange={changedTimeLineSlider}
        />
        {!timelineMinimized && (
          <span style={styles.timeline_info_text}>
            This Location data is time-ranged by taking{" "}
            {props.tokenId ? "your " : `${props.accountOwner.name}'s `}
            most recent location time, and then subtracting an hour from it. If
            you don't see an updated location while sliding that means we dont
            have {props.tokenId
              ? "your "
              : `${props.accountOwner.name}'s `}{" "}
            location at that specific point in time.
          </span>
        )}
      </div>
    </div>
  );
};

const styles = {
  timeline_info_text: {
    color: "white",
    fontWeight: "700",
    fontSize: 10,
    marginBottom: 10,
    opacity: 0.7,
  },
  timeline_slider: {
    color: "white",
    width: "auto",
    //margin: "auto",
    marginRight: "10px",
    // paddingRight: 10,
    //marginBottom: 5,
  },
  timeline_time_text: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    fontSize: 18,
    marginBottom: 5,
  },
  timeline_time_title: {
    textAlign: "center",
    color: "white",
    fontWeight: "500",
    fontSize: 16,
    marginBottom: 5,
  },
  timeline_title_tag: {
    color: "black",
    fontWeight: "500",
    fontSize: 14,
    marginBottom: 10,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 5,
    textAlign: "center",
    // backgroundColor: "yellow",
  },
  timeline_content_container: {
    color: "white",
    backgroundColor: "#1982FC",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    // marginLeft: 10,
    // marginRight: 10,
    borderRadius: 20,
    fontWeight: "500",
    boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "90%",
  },
  timeline_info_button: {
    height: 30,
    width: 30,
    color: "white",
    backgroundColor: "#1982FC",
    padding: 5,
    borderRadius: 50,
    fontWeight: "500",
    textAlign: "center",
    boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 21,
  },
  exit_timeline_button: {
    color: "white",
    backgroundColor: "#1982FC",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 20,
    fontWeight: "500",
    boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
    textAlign: "center",
    marginRight: 5,
  },
  timeline_controls_container: {
    display: "flex",
    flexDirection: "row",
  },
  call_911_button_timeline_on: {
    textDecoration: "none",
    color: "black",
    // width: "40%",
    backgroundColor: "#FFBABA",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: 30,
    fontWeight: "500",
    zIndex: 20,
  },
  main_container_timeline_on: {
    display: "flex",
    flexDirection: "column",
    bottom: 30,
    position: "absolute",
    zIndex: 20,
    width: "100%",
    // backgroundColor: "green",
  },
  timeline_on_buttons_container: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "yellow",
    justifyContent: "space-between",
    zIndex: 21,
    // marginLeft: 10,
    // marginRight: 10,
    marginBottom: 5,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    // backgroundColor: "yellow",
  },
  main_container_timeline_off: {
    display: "flex",
    flexDirection: "column",
    // width: "100%",
    position: "absolute",
    bottom: 30,
    zIndex: 11,
    marginLeft: 10,
    //backgroundColor: "green",
  },
  timeline_on_button: {
    // width: "40%",
    // maxWidth: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#1982FC",
    fontWeight: "500",
    borderRadius: 20,
    height: 30,
    color: "white",
    boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
    // marginBottom: 10,
  },
  call_emergency_person_button: {
    textDecoration: "none",
    color: "black",
    backgroundColor: "#FFBABA",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
    borderRadius: 20,
    //textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "500",
  },
  call_911_button_timeline_off: {
    textDecoration: "none",
    color: "black",
    backgroundColor: "#FFBABA",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
    borderRadius: 20,
    //textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    fontWeight: "500",
  },
};

export default TimelineFeature;
