import "./App.css";

import {
  BrowserRouter,
  Switch,
  Redirect,
  Route,
  Routes,
} from "react-router-dom";
import MapView from "./components/main/map/mapview";
import Login from "./components/main/login/login";
function App() {
  return (
    <div style={styles.container} id="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="/l/*" element={<MapView />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const styles = {
  container: {
    height: "100%",
    //height: '100%',
    width: "100%",
    // overflow: 'auto',
    //display: "flex",
    //alignItems: 'center',
    //justifyContent: 'center'
  },
};

export default App;
