import React, { useEffect, useState, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import { postLoginCode, postLoginPhone } from "../../../managers/apimanager";
import toast, { Toaster } from "react-hot-toast";
import { formatNumber, isValidPhoneNumber } from "../../../libraries/phonelib";

const Login = (props) => {
  const [phone, setPhone] = useState("");
  const [codeMode, setCodeMode] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCodeMode(false);
    setLoading(false);
    setPhone("");
    setCode("");
  }, []);
  var windowHeight = window.innerHeight;

  async function buttonClicked() {
    setLoading(true);
    if (codeMode) {
      if (code.length < 6) {
        toast(`Please enter a 6 digit code.`);
        setLoading(false);
        return;
      }

      await postLoginCode(phone, code)
        .then(function (result) {
          console.log(result);
          if (result?.code === "SUCCESS") {
            var accountInformation = result.data.account;
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify(accountInformation)
            );
            console.log(accountInformation);
            setCodeMode(false);
            setCode("");
          } else {
            if (result?.data?.code === "INVALID_PHONE") {
              toast(
                `There is an issue with your phone number, please double check it.`
              );
            } else if (result?.data?.code === "INVALID_CODE") {
              toast(`This code is incorrect.`);
            }
          }
        })
        .catch(function (e) {
          console.log(e);
          toast(
            `There seems to have been an error on our end. We apologize about this.`
          );
        });

      // setCodeMode(false);
    } else {
      if (phone.length < 1 || !isValidPhoneNumber(phone)) {
        toast(`Please enter a valid phone number.`);
        setLoading(false);
        return;
      }
      await postLoginPhone(formatNumber(phone))
        .then(function (result) {
          console.log(result);
          if (result?.code === "SUCCESS") {
            setCodeMode(true);
            setCode("");
          } else {
            if (result?.data?.code === "INVALID_PHONE") {
              toast(
                `There is an issue with your phone number, please double check it.`
              );
            }
          }
        })
        .catch(function (e) {
          console.log(e);
          toast(
            `There seems to have been an error on our end. We apologize about this.`
          );
        });
    }
    setLoading(false);
  }
  async function backButtonClicked() {
    setCodeMode(false);
    setCode("");
  }
  async function inputTextChanged(event) {
    if (codeMode) setCode(event.target.value);
    else setPhone(event.target.value);

    console.log({
      code,
      phone,
    });
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        width: "80%",
        height: windowHeight,
        alignContent: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <Toaster />
      {codeMode && (
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
          onClick={backButtonClicked}
        >
          <ArrowBackIcon
            fontSize="22"
            style={{
              //   fontWeight: "bold",
              //   backgroundColor: "red",
              marginRight: 10,
            }}
          />
          <span
            style={{
              fontSize: 18,
              fontWeight: "500",
              // backgroundColor: "green",
              // textAlign: "center",
            }}
          >
            Phone Entry
          </span>
        </div>
      )}

      <span
        style={{
          fontSize: 16,
          fontWeight: "500",
          marginBottom: 10,
        }}
      >
        {codeMode ? "Code" : "Phone Number"}
      </span>
      <input
        style={{
          height: 50,
          fontSize: 22,
          fontWeight: "bold",
          textAlign: "center",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottomWidth: 0.5,
          borderBottomColor: "#DEDEDE",
          marginBottom: 10,
          outline: "none",
        }}
        type="tel"
        value={codeMode ? code : phone}
        onChange={inputTextChanged}
      ></input>
      <span
        style={{
          fontSize: 12,
          fontWeight: "500",
          marginBottom: 15,
          color: "#DEDEDE",
        }}
      >
        {codeMode
          ? "We've sent your phone number an SMS code, please enter your sms code in order to login."
          : "Please enter your phone number in order to login. Simple as that!"}
      </span>
      <span
        style={{
          backgroundColor: "#ebebeb",
          padding: 10,
          borderRadius: 10,
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        onClick={buttonClicked}
      >
        {codeMode ? "Verify" : "Next"}
        {loading && (
          <CircularProgress
            size={12}
            style={{
              marginLeft: 10,
              color: "black",
            }}
          />
        )}
      </span>
    </div>
  );
};

export default Login;
