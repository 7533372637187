import React, { useEffect, useState, useRef } from "react";
import {
  getAllContactsSecure,
  getAllContactsSecure_Token,
  postLocation,
} from "../../../managers/apimanager";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { Pin } from "./pin";
import "react-toastify/dist/ReactToastify.css";
import style from "./mapstyle.json";
import { useLocation, useNavigate } from "react-router-dom";
import OnlineOffline from "./onlineoffline";
import styles from "./mapview.module.css";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import {
  Divider,
  ListItemButton,
  ListSubheader,
  Modal,
  Popover,
  Typography,
  Box,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import Location from "./location/location";
import EmergencyCalls from "./emergencycalls";
import TimelineFeature from "./timelinefeature";

const MapView = (props) => {
  const [allContacts, setAllContacts] = useState([]);
  const [pins, setPins] = useState([]);
  const [accountOwner, setAccountOwner] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [linkId, setLinkId] = useState(null);
  const [timeline, setTimeLine] = useState({
    active: false,
    pins: [],
  });
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [mapOptions, setMapOptions] = useState({
    zoom: 14,
    center: { lat: 34.97802825740756, lng: -98.47939944815275 },
  });
  const myParam = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(timeline);
  }, [timeline]);

  async function pullAllContacts(contactId, linkId, tokenId) {
    var contacts = contactId
      ? await getAllContactsSecure(contactId, linkId)
      : await getAllContactsSecure_Token(tokenId, linkId);
    contacts = contacts.data.contacts;
    //console.log(`Pulled all contacts: `, contacts.length);
    for (var contact of contacts) {
      if (contact.linkId) setAccountOwner(contact);
    }
    setAllContacts(contacts);
  }
  const getUserLocation = async () => {
    // Promisify Geolocation.getCurrentPosition since it relies on outdated callbacks
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var { latitude, longitude } = position.coords;
          // latitude = latitude * (1 + getRandomFloat(0.00001, 0.00002, 10))
          //longitude = longitude * (1 + getRandomFloat(0.00001, 0.00002, 10))
          resolve([latitude, longitude]);
        },
        (error) => {
          // logDump({
          //     fullLink: window.location.href,
          //     error: {
          //         code: error.code,
          //         message: error.message,
          //     }
          // })
          // setLocationError({
          //     error: true,
          //     timestamp: new Date().getTime()
          // })
          //toast(`Error getting location,${error.message}`)
          //toast.error(`Error getting location,${error}`, toastOptions)
          // reject(error);
          resolve(false);
        }
      );
    });
  };
  const parseUrl = () => {
    var location = window.location;
    //console.log(location)
    var params = [];
    if (!location.pathname.includes("/l/")) params = [];
    else {
      var url = location.pathname.replace("/l/", "");
      params = url.split("/").filter((e) => e.length > 0);
    }
    return params;
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    async function doUseEffect() {
      //Handle Parameters
      //Extract Token First (If there is one)
      var searchParams = new URLSearchParams(myParam);
      var tokenId = searchParams.get("tokenId");

      //Extract Contact Id and Link Id Next
      var parameters = parseUrl();
      console.log(`Params`, parameters);
      var contactId = parameters[1];
      var linkId = parameters[0];

      //If there isnt a contactId or a tokenId, we can't proceed with this screen.
      if (!contactId && !tokenId) return;

      //Set the state for contact id, link id, and token id
      setContactId(contactId);
      setLinkId(linkId);
      setTokenId(tokenId);

      //Do the first API pull
      var contacts = contactId
        ? await getAllContactsSecure(contactId, linkId, tokenId)
        : await getAllContactsSecure_Token(tokenId, linkId);
      //console.log(contacts);
      if (contacts.code === "ERROR") {
        //console.log(`API Returned an Error`);
        return true;
      }
      contacts = contacts.data.contacts;
      for (var contact of contacts) {
        if (contact.linkId) {
          setMapOptions({
            zoom: 14,
            center: {
              lat: contact.recentLocationCoords[0],
              lng: contact.recentLocationCoords[1],
            },
          });
          setAccountOwner(contact);
        }
      }
      setAllContacts(contacts);

      setInterval(async () => {
        pullAllContacts(contactId, linkId, tokenId);
      }, 2000);

      // var intervalId = setInterval(async () => {
      //     var userLocation = await getUserLocation()
      //     if (!userLocation) clearInterval(intervalId)
      //     //if (userLocation) postContactLocationMap(contactId, linkId, userLocation[0], userLocation[1])
      // }, 2000)

      // return () => clearInterval(intervalId);
    }

    doUseEffect();
  }, []);

  function updatePins(contacts) {
    //console.log(`Updating Pins:`);
    var pins = [];
    for (var c of contacts) {
      //console.log(`Contact:`, c);
      if (!c.recentLocationCoords) continue;
      pins.push(
        <Pin
          tokenId={tokenId}
          accountOwner={c.linkId ? accountOwner : null}
          contact={c}
          lat={c.recentLocationCoords[0]}
          lng={c.recentLocationCoords[1]}
        />
      );
    }
    setPins(pins);
  }
  useEffect(() => {
    //console.log(`Map Options:`, mapOptions);
  }, [mapOptions]);
  useEffect(() => {
    //console.log(`Use Effect: [allContacts]`);
    updatePins(allContacts);
  }, [allContacts]);

  document.body.style.overflow = "hidden";
  var windowHeight = window.innerHeight;
  return (
    <div>
      {accountOwner && map && allContacts.length > 0 && !timeline.active && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            top: 60,
            right: 10,
            display: "flex",
            justifyContent: "center",
            // width: "100%",
            //backgroundColor: "white",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              fontWeight: "500",
              // backgroundColor: "#1982FC",
              backgroundColor: "white",
              borderRadius: 20,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 10,
              // marginLeft: 10,
              color: "black",
              // alignItems: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
            }}
            onClick={() => {
              if (
                allContacts.filter((element) => element.recentLocationCoords)
                  .length <= 1
              ) {
                setMapOptions({
                  center: {
                    lat: accountOwner.recentLocationCoords[0],
                    lng: accountOwner.recentLocationCoords[1],
                  },
                  zoom: 14,
                });
              } else {
                if (map) {
                  const bounds = new window.google.maps.LatLngBounds();
                  for (var contact of allContacts) {
                    if (contact.recentLocationCoords) {
                      bounds.extend({
                        lat: contact.recentLocationCoords[0],
                        lng: contact.recentLocationCoords[1],
                      });
                    }
                  }
                  map.fitBounds(bounds);
                }
              }
            }}
          >
            {/* {allContacts.filter((element) => element.recentLocationCoords)
              .length <= 1
              ? `Show ${accountOwner.name}'s Position`
              : "Show Everyone's Positions"} */}

            <MyLocationIcon
              sx={{
                height: 20,
                width: 20,
                // marginLeft: "5px"
              }}
            />
          </span>
        </div>
      )}
      {/* {accountOwner && !tokenId && !timeline.active && (
        <EmergencyCalls
          accountOwner={accountOwner}
          timelineMode={timeline.active}
        />
      )} */}
      {accountOwner && (
        <TimelineFeature
          setMapOptions={setMapOptions}
          accountOwner={accountOwner}
          setTimeLine={setTimeLine}
          tokenId={tokenId}
          linkId={linkId}
          contactId={contactId}
          map={map}
          maps={maps}
        />
      )}
      {allContacts.length > 0 && !timeline.active && (
        <OnlineOffline allContacts={allContacts} />
      )}
      {allContacts.length > 0 && !tokenId && !timeline.active && (
        <Location
          contactId={contactId}
          linkId={linkId}
          accountOwner={accountOwner}
        />
      )}

      <div
        style={{
          height: window.innerHeight,
          width: "100%",
          position: "absolute",
          zIndex: 0,
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDLDiqoavUI8IjaXEtaZ_vPpGt88Gw_2FU" }}
          center={mapOptions.center}
          // defaultCenter={{ lat: contact.recentLocationCoords[0], lng: contact.recentLocationCoords[1] }}
          zoom={mapOptions.zoom}
          options={{
            zoomControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            styles: style,
            clickableIcons: false,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={function ({ map, maps }) {
            setMap(map);
            setMaps(maps);
          }}
          onChange={(data) => {
            setMapOptions({ zoom: data.zoom, center: data.center });
          }}
        >
          {
            pins.concat(
              timeline.pins
            ) /* {pins.length > 0 ? (timeline.active ? timeline.pins : pins) : null} */
          }
        </GoogleMapReact>
      </div>
      {/* {pins.length > 0 &&
                <a href="tel:911">
                    <Typography
                        className={styles.MapView_911Button}
                        sx={{
                            fontSize: "1.5em"
                        }}
                    >Call 911</Typography>
                </a>

            } */}
    </div>
  );
};

export default MapView;
