import { Modal } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { postLocation } from "../../../../managers/apimanager";
import styles from "./location.module.css";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
const Location = (props) => {
  const [locationModal, setLocationModal] = useState(false);
  const [fetchingLocation, setFetchingLocation] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [sharingLocation, setSharingLocation] = useState(false);
  const getUserLocation = async () => {
    // Promisify Geolocation.getCurrentPosition since it relies on outdated callbacks
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var { latitude, longitude } = position.coords;
          // latitude = latitude * (1 + getRandomFloat(0.00001, 0.00002, 10))
          //longitude = longitude * (1 + getRandomFloat(0.00001, 0.00002, 10))
          resolve([latitude, longitude]);
        },
        (error) => {
          console.log(`Error Fetching Location:`, error);
          resolve(false);
        }
      );
    });
  };
  function clickShareMyLocationButton() {
    setLocationModal(true);
  }
  function onCloseModal() {
    setLocationModal(false);
  }
  async function clickModalShareLocationButton() {
    console.log(`Clicked Share My Location Button`);
    setFetchingLocation(true);
    var userLocation = await getUserLocation();
    if (!userLocation) setLocationError(true);
    else {
      postLocation(props.contactId, props.linkId, [
        userLocation[0],
        userLocation[1],
      ]);
      var intervalId = setInterval(async () => {
        var userLocation = await getUserLocation();
        if (!userLocation) clearInterval(intervalId);
        if (userLocation)
          postLocation(props.contactId, props.linkId, [
            userLocation[0],
            userLocation[1],
          ]);
      }, 2000);
      setLocationError(false);
      setLocationModal(false);
      setSharingLocation(true);
    }
    setFetchingLocation(false);
  }
  return (
    <div>
      <Modal
        open={locationModal}
        onClose={onCloseModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "80%",
            backgroundColor: "white",
            borderRadius: 20,
            padding: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CloseIcon onClick={onCloseModal} style={{ marginBottom: 10 }} />
          <div
            style={{
              backgroundColor: "#F5F5F5",
              borderRadius: 20,
              padding: 10,
              marginBottom: sharingLocation ? 0 : 10,
            }}
          >
            {!sharingLocation ? (
              <span>
                Share your location with {props.accountOwner.name} and their
                emergency contacts?{" "}
              </span>
            ) : (
              <span>
                You are currently sharing your location with{" "}
                {props.accountOwner.name} and their emergency contacts. Close
                this web page to stop sharing.
              </span>
            )}
          </div>
          {locationError && (
            <div
              style={{
                backgroundColor: "#FEE9F0",
                marginBottom: 10,
                padding: 10,
                borderRadius: 20,
              }}
            >
              <span>
                There seems to be an issue getting your location. It could be:
                <br />
                *A permission issue
                <br />
                *A browser issue
                <br />
                *Or using incognito
                <br />
                Try refreshing your browser maybe.
              </span>
            </div>
          )}

          {fetchingLocation ? (
            <CircularProgress
              size={18}
              sx={{
                margin: "auto",
              }}
            />
          ) : (
            !sharingLocation && (
              <span
                style={{
                  backgroundColor: "#1982FC",
                  borderRadius: 20,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: "white",
                  boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
                  textAlign: "center",
                }}
                onClick={clickModalShareLocationButton}
              >
                Yes, Share My Location
              </span>
            )
          )}
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          top: 10,
          zIndex: 1,
          position: "absolute",
        }}
        onClick={clickShareMyLocationButton}
      >
        <span
          style={{
            backgroundColor: sharingLocation ? "#D3FFD8" : "white",
            borderRadius: 20,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 10,
            color: "black",
            boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
            fontWeight: "500",
          }}
        >
          {sharingLocation ? "Sharing Location" : "Share My Location"}
        </span>
      </div>
    </div>
  );
};

export default Location;
