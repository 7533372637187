export const TimelinePin = ({ contact, time, highlighted }) => (
  <div
    style={{
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 50,
      boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
      zIndex: 20000,
    }}
    onClick={() => {}}
  >
    {contact.recentLocationTime && (
      <div
        style={{
          width: 8,
          height: 8,
          position: "absolute",
          backgroundColor: "grey",
          top: -3,
          left: -3,
          borderRadius: "50%",
        }}
      ></div>
    )}

    <div
      style={{
        position: "absolute",
        left: 7,
        textAlign: "center",
        backgroundColor: "white",
        padding: 2,
        borderRadius: 5,
        fontSize: highlighted ? 14 : 8,
      }}
    >
      {`${new Date(time).toLocaleString(`en-US`, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      })}`}
    </div>
  </div>
);
