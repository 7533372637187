import React, { Component } from 'react';
import defaultProfilePhoto from "../../../assets/blank.png"

export default class Image extends Component {
    render() {
        let { mode, src, height, width, style, opacity, ...props } = this.props;
        let modes = {
            'fill': 'cover',
            'fit': 'contain'
        };
        let size = modes[mode] || 'contain';

        let defaults = {
            height: height || 100,
            width: width || 100,
            backgroundColor: 'gray',
            borderRadius: "50%"
        };
        let important = {
            backgroundImage: `url("${src}")`,
            backgroundSize: size,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            opacity
        };

        return <div {...props} style={{ ...defaults, ...style, ...important }} />
    }
}