import React, { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import defaultProfilePhoto from "../../../assets/blank.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import styles from "./mapview.module.css";
import {
  Divider,
  ListItemButton,
  ListSubheader,
  Modal,
  Popover,
  Typography,
  Box,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { bgcolor } from "@mui/system";
import { getAllContactByAccountId } from "../../../managers/apimanager";

const OnlineOffline = (props) => {
  var [onlinePeople, setOnlinePeople] = useState([]);
  var [offlinePeople, setOfflinePeople] = useState([]);
  var [collapse, setCollapse] = useState(true);

  const setStates = async () => {
    var online = [];
    var offline = [];
    for (var contact of props.allContacts) {
      if (
        contact.recentLocationTime &&
        contact.recentLocationTime > new Date().getTime() - 10000
      )
        online.push(
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar src={contact.photo || defaultProfilePhoto}></Avatar>
            </ListItemAvatar>
            <ListItemText primary={contact.name || "Anon"}></ListItemText>
          </ListItem>
        );
      else
        offline.push(
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar src={contact.photo || defaultProfilePhoto}></Avatar>
            </ListItemAvatar>
            <ListItemText primary={contact.name || "Anon"}></ListItemText>
          </ListItem>
        );
    }
    setOnlinePeople(online);
    setOfflinePeople(offline);
  };

  useEffect(() => {
    console.log(`Use Effect: [props.allContacts]`);
    setStates();
  }, [props.allContacts]);

  return (
    <div>
      <div
        className={styles.OnlineOfflineView_whosOnlineButton}
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <PeopleIcon />
      </div>

      {!collapse && (
        <div className={styles.OnlineOfflineView_main}>
          <List disablePadding>
            {onlinePeople.length > 0 ? (
              <div>
                <div
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <span className={styles.OnlineOfflineView_onlineHeader}>
                    Online
                  </span>
                </div>

                {onlinePeople}
              </div>
            ) : (
              <div></div>
            )}
            {onlinePeople.length > 0 && offlinePeople.length > 0 && (
              <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            )}
            {offlinePeople.length > 0 ? (
              <div>
                <div
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <span className={styles.OnlineOfflineView_offlineHeader}>
                    Offline
                  </span>
                </div>

                {offlinePeople}
              </div>
            ) : (
              <div></div>
            )}
          </List>
        </div>
      )}
    </div>
  );
};

export default OnlineOffline;
