import Image from "./image";
import defaultProfilePhoto from "../../../assets/blank.png";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import PulsatingDot from "./pulsatingdot";
export const Pin = ({ contact, accountOwner, tokenId, callButtonOverlay }) => (
  <a href={`tel:${contact.phone || contact.phoneNumber}`}>
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignItems: "center",
        borderRadius: 5,
        boxShadow: "1px 1px 11px -4px rgba(0,0,0,0.75)",
      }}
      onClick={() => {}}
    >
      <Image
        src={contact.photo || defaultProfilePhoto}
        width={50}
        height={50}
        mode="fill"
        opacity={1.0}
        style={{
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />
      <div
        style={{
          //bottom: "-20px",
          marginTop: 2,
          textAlign: "center",
          color: "black",
          //borderRadius: "2px",
          //padding: "2px",
          backgroundColor: "white",
          //border: "0.5px solid #E5E5E5",
        }}
      >
        {contact.name || "Anon"}
      </div>
      {contact.recentLocationTime &&
      contact.recentLocationTime > new Date().getTime() - 10000 ? (
        <div
          style={{
            position: "absolute",
            top: -10,
            left: -10,
          }}
        >
          <PulsatingDot />
        </div>
      ) : (
        <div
          style={{
            width: 8,
            height: 8,
            position: "absolute",
            backgroundColor: "red",
            top: -10,
            left: -10,
            borderRadius: "50%",
          }}
        ></div>
      )}

      <div
        style={{
          marginTop: "2px",
          //bottom: "-20px",
          textAlign: "center",
          backgroundColor: "white",
          //borderRadius: "5",
          //padding: "2px",
          color:
            contact.recentLocationTime &&
            contact.recentLocationTime > new Date().getTime() - 10000
              ? "green"
              : "red",
          //border: "0.5px solid #E5E5E5",
          //width: "50px",
          marginBottom: 2,
        }}
      >
        {contact.recentLocationTime &&
        contact.recentLocationTime > new Date().getTime() - 10000
          ? `Online`
          : `Offline`}
        {(!contact.recentLocationTime ||
          contact.recentLocationTime <= new Date().getTime() - 10000) && (
          <div>
            {`${new Date(contact.recentLocationTime).toLocaleDateString(
              `en-US`,
              {
                day: "numeric",
                month: "short",
              }
            )},`}
            <br></br>
            {`${new Date(contact.recentLocationTime).toLocaleString(`en-US`, {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}`}
          </div>
        )}
      </div>
      {accountOwner && !tokenId && (
        <div
          style={{
            width: 50,
            backgroundColor: "#1982FC",
            zIndex: 10,
            position: "absolute",
            borderRadius: 10,
            right: -75,

            top: 0,
            display: "flex",
            alignItems: "center",
            padding: 10,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: 0,
              width: 0,
              top: 6,
              left: -2,
              // backgroundColor: "#1982FC",
              border: "5px solid #1982FC",
              position: "absolute",
              borderTopColor: "transparent",
              borderRightColor: "transparent",
              transform: "rotate(45deg)",
            }}
          ></div>
          <span
            style={{
              color: "white",
            }}
          >
            {accountOwner.name} is in an emergency.
            {/* {contact.emergencyMessage} */}
            {/* Hi */}
          </span>
        </div>
      )}
      {!tokenId && (
        <div
          style={{
            backgroundColor: "#1982FC",
            zIndex: 10,
            width: 40,
            position: "absolute",
            borderRadius: 2,
            height: 10,
            top: callButtonOverlay ? 3 : -20,
            opacity: callButtonOverlay ? 0.8 : 1,
            display: "flex",
            alignItems: "center",
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 3,
            paddingBottom: 3,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "white",
            }}
          >
            Call
          </span>
        </div>
      )}
    </div>
  </a>
);
