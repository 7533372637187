import fetch from "node-fetch";

export const getTimeline = async (tokenId, contactId, linkId) => {
  var url = "https://us-central1-helpmeio.cloudfunctions.net/helpmeiov1";
  url += `/contact/web/timeline`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ tokenId, contactId, linkId }),
  });
  const json = await data.json();
  return json;
};
export const getAllContactsSecure = async (contactId, linkId) => {
  var url = "https://us-central1-helpmeio.cloudfunctions.net/helpmeiov1";
  url += `/contact/web/${contactId}/${linkId}`;
  const data = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const json = await data.json();
  return json;
};
export const getAllContactsSecure_Token = async (tokenId, linkId) => {
  var url = "https://us-central1-helpmeio.cloudfunctions.net/helpmeiov1";
  url += `/contact/web_android/${tokenId}/${linkId}`;
  const data = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const json = await data.json();
  return json;
};

export const postLocation = async (contactId, linkId, coords) => {
  var url = "https://us-central1-helpmeio.cloudfunctions.net/helpmeiov1";
  url += `/contact/web/${contactId}/${linkId}`;
  const data = await fetch(url, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ recentLocationCoords: coords }),
  });
  const json = await data.json();
  return json;
};

export const postLoginPhone = async (phone) => {
  var url = "https://us-central1-helpmeio.cloudfunctions.net/helpmeiov1";
  url += `/account/login/phone`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phone }),
  });
  const json = await data.json();
  return json;
};
export const postLoginCode = async (phone, code) => {
  var url = "https://us-central1-helpmeio.cloudfunctions.net/helpmeiov1";
  url += `/account/login/code`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phone, code }),
  });
  const json = await data.json();
  return json;
};
